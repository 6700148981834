import { cediSign, dateToHumanFormatShort } from '../../utils/constants';
import { BanknotesIcon } from '@heroicons/react/24/solid';
import { NumCount } from "../../components";

function DisburseSum({ disburseData, days }) {

    return (
        <div className='border border-gray-200/[.8] rounded-lg p-6 inline-flex flex-col space-y-2 shadow-sm'>
            <div className='inline-flex flex-row items-center justify-between text-sm'>
                <span className='font-medium'>Total disbursed</span>
                <BanknotesIcon className='w-5 h-5 sm:hidden md:block text-light-blue' />
                {/* <span className='font-medium sm:hidden md:block'>GHC</span> */}
            </div>

            <div>
                <span className='text-4xl font-bold tabular-nums'>
                    <NumCount symbol={cediSign} number={disburseData?.todayTotal} deci={"."} />
                </span>
            </div>

            <div>
                <span className='text-xs font-light text-gray-500'>
                    {/* + {percentDaily(disburseData?.prevTotal,disburseData?.todayTotal)}% from yesterday */}
                    {days?.firstDay !== null && `From ${dateToHumanFormatShort(days?.firstDay)} to ${dateToHumanFormatShort(days?.lastDay)}`}
                </span>
            </div>
        </div>
    )
}

export default DisburseSum