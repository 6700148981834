import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { selectAccessToken } from "../../slices/staffSlice";
import { fetchRequests, accessFileRequest } from '../../Requests/getRequests';
import { Button } from "../../components";
import { authStaff, reportTypes } from '../../utils/constants';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { show } from '../../slices/toastSlice';
import { useNavigate } from 'react-router-dom';

function OtherReports() {

    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [reportType, setReportType] = useState("");
    const [toDate, setToDate] = useState("");
    const [process, setProcess] = useState(false);
    const [success, setSuccess] = useState(false);
    const [reportLink, setReportLink] = useState("");

    const handleRequest = async(e) => {
        e.preventDefault();
        setProcess(true);

        const params = {
            toDate : toDate
        }

        let url = `/reports/staff/${reportType}`;
        let headers = authStaff(accessToken);

        let res = await fetchRequests(url, "get", headers, params, {});
        setProcess(false);

        if(res?.code === 401){
			navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        }

        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : `${res?.err}`
            }))
            return;
        }

        dispatch(show({
            type : "success",
            state : true,
            message : `Report generated successfully`
        }))

        setSuccess(true);
        setReportLink(res)
        
    }

    const downloadReport = async() => {
        let url = `/reports/staff/getReportFile`;
        let headers = authStaff(accessToken)
        let params = {
            name : reportLink.split("/")[1]
        }

        let res = await accessFileRequest(url, headers, params);
        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : `${res?.err}`
            }))
            return;
        }

        const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = fileUrl;
        const fileName = res.headers['content-disposition'].split('filename=')[1];
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    return (
        <tr>
            <td className='px-4 py-3'>
                <select name="report-type" id="report-type" className='form-input' required onChange={(e) => setReportType(e.target.value)}>
                    <option>-- select option --</option>
                    {reportTypes?.map((type) => (
                        <option key={type.id} value={type.value}>{type.name}</option>
                    ))}
                </select>
            </td>
            <td className='px-4 py-3'>
                <input type="date" name="to-date" id="to-date" min="2021-01-01" max="2040-01-01" className='form-input' required onChange={(e) => setToDate(e.target.value)} />
            </td>
            <td className='px-4 py-3 inline-flex items-center space-x-4'>
                <Button 
                    btnText={"Generate"}
                    btnType="button"
                    btnClasses="form-button mt-2"
                    btnFunction={handleRequest}
                    processing={process}
                    disabled={reportType !== "" && toDate !== "" ? false : true}
                />
                
                {success && 
                    <button type="button" className='mt-2 flex items-center justify-center px-3 py-2 rounded-md bg-slate-100 hover:bg-slate-200' onClick={downloadReport}>
                        <ArrowDownTrayIcon className="w-5 h-5 mr-2" /> Download
                    </button>
                }
            </td>
        </tr>
    )
}

export default OtherReports