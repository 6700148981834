import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from "../..";
import { authStaff } from "../../../utils/constants";
import { adminPortalRequest } from "../../../Requests/getRequests";
import { useSelector } from "react-redux";
import { selectAccessToken, selectStaffId } from "../../../slices/staffSlice";

function PerformAction({ action, url, setAlert }) {

    const accessToken = useSelector(selectAccessToken);
    const clientId = useSelector(selectStaffId);
    const [process, setProcess] = useState(false);

    const actionRequest = async(e) => {
        e.preventDefault();
        setProcess(true);

        let verifyUrl = url;
        let headers = authStaff(accessToken);
        let data = {
            userId : clientId
        }

        let res = await adminPortalRequest(verifyUrl, "PUT", headers, {}, data);
        if(res?.code){
            setAlert({
                type : "error",
                state : true,
                message : `${res?.message}`
            })
            setTimeout(() => {
                setAlert({
                    state : false
                })
            }, 2000)
        } else {
            setAlert({
                type : "success",
                state : true,
                message : `${res?.message}`
            })
            setTimeout(() => {
                setAlert({
                    state : false
                })
            }, 2000)
        }

        setProcess(false);
    }

    return (
        <div className='flex flex-col items-start'>
            <span className='text-gray-500'>
                Doing this will <span className='lowercase'>{action}</span> the account 
            </span>

            <div className='inline-flex item-center space-x-3 mt-5'>
                <Button 
                    btnText={action}
                    btnClasses={action === "Ban" ? "danger-button" : "green-button"}
                    btnType={"button"}
                    btnFunction={actionRequest}
                    processing={process}
                />

                <Link to="/admin/clients" className='cancel-button'>
                    Cancel
                </Link>
            </div>
        </div>
    )
}

export default PerformAction