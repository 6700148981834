import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Repay, AccountTabs, Transactions, LoanBalance, Button } from "../../../components";
import RepayDates from "./RepayDates";
import { selectAccessToken } from "../../../slices/staffSlice";
import { authStaff } from "../../../utils/constants";
import { accessFileRequest } from "../../../Requests/getRequests";
import { show } from "../../../slices/toastSlice";

function Index({ loan }) {

    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const [processing, setProcessing] = useState(false);

    const downloadReport = async() => {
        setProcessing(true);
        let url = `/reports/staff/getReportFile`;
        let headers = authStaff(accessToken)
        let params = {
            name : loan?.url.split("/")[1]
        }

        let res = await accessFileRequest(url, headers, params);
        setProcessing(false);

        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : `${res?.err}`
            }))
            return;
        }

        const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = fileUrl;
        const fileName = res.headers['content-disposition'].split('filename=')[1];
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    return (
        <div className='application-content flex flex-col justify-center space-y-8'>
            {/* Repayment section */}
            {loan?.state === "active" && (
                <Repay loan={loan} />
            )}
            {/* Repayment section */}

            {/* Loan settings (toggle reschedule and messages) */}
            {/* {loan?.state === "active" && (
                <Settings loan={loan} />
            )} */}
            {/* Loan settings (toggle reschedule and messages) */}

            <AccountTabs loan={loan} />

            {/* days in arrears counter */}
            {loan?.state === "active" && loan?.daysInDelinq && loan?.daysInDelinq > 0 ? (
                <div className='mt-6'>
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                        <strong className="font-bold">Notice:</strong>
                        <span className="block sm:inline"> Client is <span className='font-bold'>{loan?.daysInDelinq}</span> days behind in payments. Please alert client to make payment to avoid penalties.</span>
                    </div>
                </div>
            ) : (
                <></>
            )}
            {/* days in arrears counter */}

            {/* loan repayment dates */}
            {loan?.state === "active" && loan?.standingOrderDates && loan?.standingOrderDates?.length > 0 && (
                <>
                <h4 className="text-xl font-semibold text-gray-600">Standing Order Payment dates (+5)</h4>
                <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8 gap-4">
                    <RepayDates dates={loan?.standingOrderDates} />
                </div>
                </>
            )}

            {loan?.state === "active" && loan?.paymentDates && loan?.paymentDates?.length > 0 && (
                <>
                <h4 className="text-xl font-semibold text-gray-600">Payment dates</h4>
                <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8 gap-4">
                    <RepayDates dates={loan?.paymentDates} />
                </div>
                </>
            )}
            {/* loan repayment dates */}

            {(loan?.state === "active" || loan?.state === "completed") && (
                <>
                    <LoanBalance loan={loan} />
                    <Transactions loan={loan} />

                    <div>
                        <Button 
                            btnText={"Generate Statement"}
                            btnClasses={"form-button w-auto px-6 mb-2"}
                            btnType={"button"}
                            btnFunction={downloadReport}
                            processing={processing}
                        />
                    </div>
                </>
            )}
            
        </div>
    )
}

export default Index