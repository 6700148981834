import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./utils/ProtectedRoute";
import { Login, Register, ForgotPassword, DashboardIndex, Client, LoansIndex, DetailIndex, ActiveLoans, ActiveLoan, CompletedLoans, RejectedLoans, ApprovedLoan, FacilityLetter, Portfolio, ReversalIndex, PaymentIndex, AdminIndex, StaffIndex, StaffCreate, StaffEdit, ClientIndex, ClientCreate, ClientEdit, NotFound, PreloanIndex, Questions } from "./components";

function App() {
    return (
        <>
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />

            <Route element={<ProtectedRoute />}>
                <Route path="/" exact element={<DashboardIndex />} />
                <Route path="/client/:id" exact element={<Client />} />
                <Route path="/loans" element={<LoansIndex />} />
                <Route path="/:type/:loanId" element={<DetailIndex />} />

                {/* approved loans */}
                <Route path="/approved/:type/:loanId" element={<ApprovedLoan />} />
                {/* end of approved loans */}

                {/* sign facility-letters, collective active and completed loans */}
                <Route path="/facility-letters" element={<FacilityLetter />} />
                <Route path="/active-loans" element={<ActiveLoans />} />
                <Route path="/completed-loans" element={<CompletedLoans />} />
                {/* end of sign facility-letters, collective active and completed loans */}

                {/* active and commpleted loans */}
                <Route path="/active/:loanId" element={<ActiveLoan />} />
                <Route path="/completed/:loanId" element={<ActiveLoan />} />
                {/* end of active and commpleted loans */}

                {/* rejected loans */}
                <Route path="/rejected-loans" element={<RejectedLoans />} />
                <Route path="/rejected/:loanId" element={<ActiveLoan />} />
                {/* rejected loans */}

                <Route path="/dashboard" element={<Portfolio />} />
                <Route path="/reversals" element={<ReversalIndex />} />
                <Route path="/payments" element={<PaymentIndex />} />

                <Route path="/preloan" element={<PreloanIndex />} />
                <Route path="/apply/:loanType" element={<Questions />} />

                {/* Admin urls */}
                <Route path="/admin/home" element={<AdminIndex />} /> 
                <Route path="/admin/staff" element={<StaffIndex />} />
                <Route path="/admin/staff/create" element={<StaffCreate />} />
                <Route path="/admin/staff/edit" element={<StaffEdit />} />
                {/* end of Admin urls */}
                
                {/* Client urls */}
                <Route path="/admin/clients" element={<ClientIndex />} /> 
                <Route path="/admin/clients/create" element={<ClientCreate />} />
                <Route path="/admin/clients/edit" element={<ClientEdit />} />
                {/* end of Client urls */}

			</Route>

            {/* error 404 page */}
            <Route path="*" element={<NotFound />} />
            {/* error 404 page */}
        </Routes>
        </>
    );
}

export default App;