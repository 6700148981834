import { useState } from 'react';
import { FormSelect, FormInput, FormUpload, Button, Modal, OtherDocsUpload } from "../../components";
import { optionsYN, numOfYearsWithEmployer, sectorOpts, missedPaymentOptions, billPaymentOptions, billsHistoryOpts, insuranceOpts, purposeOfLoanOpts, paymentPlanOpts, paymentDurationOpts, incomeFrequencyOpts, automaticPaymentOpts, relationshipManagers, getDateAfterTimeframe, payrollLoanOpts, relPensionTrusteeOpts, nextMonthStartDate } from '../../utils/constants';
import LoanAuth from "./LoanAuth";
import { preCheckLoanEligibility } from '../../utils/loanProcessing';
import { Dialog } from '@headlessui/react';
import EligCheck from './EligCheck';
import { useDispatch } from 'react-redux';
import { show } from '../../slices/toastSlice';
import NextOfKin from './NextOfKin';

function Employer({ client }) {

    let [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();

    const closeModal = () => {
        setIsOpen(false)
    }

    const openModal = () => {
        setIsOpen(true)
    }

    const [employedCurrently, setEmployedCurrently] = useState("");
    const [salaryStatus, setSalaryStatus] = useState("");
    const [employerMOU, setEmployerMOU] = useState("");
    const [incomeStatus, setIncomeStatus] = useState("");
    const [incomeAmt, setIncomeAmt] = useState("");
    const [debtAmt, setDebtAmt] = useState("");
    const [automatedPaymentsStatus, setAutomatedPaymentsStatus] = useState("");
    const [exployerName, setExployerName] = useState("");
    const [yearsAtWork, setYearsAtWork] = useState("");
    const [employmentSector, setEmploymentSector] = useState("");
    const [outstandingLoans, setOutstandingLoans] = useState("");
    const [loansTaken, setLoansTaken] = useState("");
    const [custRelTime, setCustRelTime] = useState("");
    const [loanDefaultStatus, setLoanDefaultStatus] = useState("");
    const [defaultedLoanStatus, setDefaultedLoanStatus] = useState("");
    const [billsOption, setBillsOption] = useState("");
    const [billsHistory, setBillsHistory] = useState("");
    const [billsHistoryStatus, setBillsHistoryStatus] = useState("");
    const [loanPaymentHistory, setLoanPaymentHistory] = useState("");
    const [billPaymentHistory, setBillPaymentHistory] = useState("");
    const [savingHabits, setSavingHabits] = useState("");
    const [savingsFreq1, setSavingsFreq1] = useState("");
    const [savingsFreq2, setSavingsFreq2] = useState("");
    const [savingsFreq3, setSavingsFreq3] = useState("");
    const [typeOfInsurance, setTypeOfInsurance] = useState("");
    const [eligibleSavingsHabit, setEligibleSavingsHabit] = useState("");
    const [amountReq, setAmountReq] = useState("");
    const [purpose, setPurpose] = useState("");
    const [paymentPlan, setPaymentPlan] = useState("");
    const [paymentDuration, setPaymentDuration] = useState("");
    const [repaySource, setRepaySource] = useState("");
    const [freqIncome, setFreqIncome] = useState("");
    const [autoDeduction, setAutoDeduction] = useState("");
    const [loanKind, setLoanKind] = useState("");
    const [incomeProof, setIncomeProof] = useState("");
    const [residenceProof, setResidenceProof] = useState("");
    const [bankOrMomoStatement, setBankOrMomoStatement] = useState("");
    const [employerMOUFile, setEmployerMOUFile] = useState("");
    const [otherDocs, setOtherDocs] = useState([]);
    const [startDate, setStartDate] = useState(getDateAfterTimeframe('daily'));
    const [guarantorFirstName, setGuarantorFirstName] = useState('');
    const [guarantorLastName, setGuarantorLastName] = useState('');
    const [guarantorPhone, setGuarantorPhone] = useState('');
    const [guarantorAddress, setGuarantorAddress] = useState('');
    const [guarantorRelationship, setGuarantorRelationship] = useState('');
    const guarantorIdType = 'GhanaCard';
    const [guarantorIdNumber, setGuarantorIdNumber] = useState('');
    const [guarantorDigitalAddress, setGuarantorDigitalAddress] = useState('');
    const [guarantorDob, setGuarantorDob] = useState('');
    const [nextOfKin, setNextOfKin] = useState('');
    const [nextOfKinPhone, setNextOfKinPhone] = useState('');
    const [nextOfKinAddress, setNextOfKinAddress] = useState('');
    const [nextOfKinRelationship, setNextOfKinRelationship] = useState('');
    const [relManager, setRelManager] = useState("");
    const [regularEmployment, setRegularEmployment] = useState("");
    const [relPensionTrustee, setRelPensionTrustee] = useState("");
    const [pensionCompliance, setPensionCompliance] = useState("");
    const signedName = client?.name;
    const [processing, setProcessing] = useState(false);
    const [preCheckData, setPreCheckData] = useState({});

    const handleRequest = async(e) => {
        e.preventDefault();
        setProcessing(true);

        let data = { phone: client?.phone, employedCurrently, salaryStatus, employerMOU, incomeStatus, incomeAmt, debtAmt, automatedPaymentsStatus, exployerName, yearsAtWork, employmentSector, outstandingLoans, loansTaken, custRelTime, loanDefaultStatus, defaultedLoanStatus, billsOption, billsHistory, billsHistoryStatus, loanPaymentHistory, billPaymentHistory, savingHabits, savingsFreq1, savingsFreq2, savingsFreq3, typeOfInsurance, eligibleSavingsHabit, amountReq, purpose, paymentPlan, paymentDuration, repaySource, freqIncome, autoDeduction, loanKind, incomeProof, residenceProof, bankOrMomoStatement, employerMOUFile, otherDocs, relManager, regularEmployment, relPensionTrustee, pensionCompliance, startDate, guarantorFirstName, guarantorLastName, guarantorPhone, guarantorAddress, guarantorRelationship, guarantorIdType, guarantorIdNumber, guarantorDigitalAddress, guarantorDob, nextOfKin, nextOfKinPhone, nextOfKinAddress, nextOfKinRelationship, signedName }

        // check to see if files have been uploaded
        let incomeFile = document.getElementById('incomeProof');
        let residenceFile = document.getElementById('residenceProof');
        let bankFile = document.getElementById('bankOrMomoStatement');
        let employerFile = document.getElementById('employerMOUFile');

        if(!incomeFile.files.length > 0){
            setProcessing(false);
            dispatch(show({
                type : "error",
                state : true,
                message : "Must attach proof of income"
            }))
            return;
        }

        if(!residenceFile.files.length > 0){
            setProcessing(false);
            dispatch(show({
                type : "error",
                state : true,
                message : "Must attach proof of residence"
            }))
            return;
        }

        if(!bankFile.files.length > 0){
            setProcessing(false);
            dispatch(show({
                type : "error",
                state : true,
                message : "Must attach bank or momo statement"
            }))
            return;
        }

        if(!employerFile.files.length > 0){
            setProcessing(false);
            dispatch(show({
                type : "error",
                state : true,
                message : "Must attach employer signed MOU"
            }))
            return;
        }

        // only submit if loan passes pre-check
        let response = await preCheckLoanEligibility("employer", data);
        setPreCheckData({ ...response, data });

        setTimeout(() => {
            setProcessing(false);
            openModal();
        },500);
    }

    return (
        <>
        <form className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-2' encType={'multipart/form-data'} onSubmit={handleRequest}>

            <div className='col-span-full'>
                <h3 className='text-xl font-medium leading-6'>
                    Income Information
                </h3>
            </div>

            <FormSelect 
                label='Are you employed with your current employer for at least 1 year?'
                name='employedCurrently'
                id="employedCurrently"
                content={optionsYN}
                value={employedCurrently}
                setValue={setEmployedCurrently}
            />
            
            <FormSelect 
                label='Is your monthly income equal to or greater than GHC2,000 per month?'
                name='salaryStatus'
                id="salaryStatus"
                content={optionsYN}
                value={salaryStatus}
                setValue={setSalaryStatus}
            />
            
            <FormSelect 
                label='Do you have Employer signed Memorandum (MOU Documentation)?'
                name='employerMOU'
                id="employerMOU"
                content={optionsYN}
                value={employerMOU}
                setValue={setEmployerMOU}
            />

            <FormSelect 
                label='Do you receive regular monthly income (Employment or other income)?'
                name='incomeStatus'
                id="incomeStatus"
                content={optionsYN}
                value={incomeStatus}
                setValue={setIncomeStatus}
            />

            <FormInput 
                label='Provide your monthly regular income'
                name='incomeAmt'
                id="incomeAmt"
                type='number'
                value={incomeAmt}
                setValue={setIncomeAmt}
            />

            <FormInput 
                label='Provide your monthly debt expenses'
                name='debtAmt'
                id="debtAmt"
                type='number'
                value={debtAmt}
                setValue={setDebtAmt}
            />

            <FormSelect 
                label='Are your loan payments automated?'
                name='automatedPaymentsStatus'
                id="automatedPaymentsStatus"
                content={optionsYN}
                value={automatedPaymentsStatus}
                setValue={setAutomatedPaymentsStatus}
            />

            <div className='col-span-full mt-10'>
                <h3 className='text-xl font-medium leading-6'>
                    Scoring Information
                </h3>
            </div>

            <FormInput 
                label='Name of your employer?'
                name='exployerName'
                id="exployerName"
                type='text'
                value={exployerName}
                setValue={setExployerName}
            />

            <FormSelect 
                label='Kindly state the number of years you worked with your current employer?'
                name='yearsAtWork'
                id="yearsAtWork"
                content={numOfYearsWithEmployer}
                value={yearsAtWork}
                setValue={setYearsAtWork}
            />

            <FormSelect 
                label='Which of the following categories of sector does your employment fall under?'
                name='employmentSector'
                id="employmentSector"
                content={sectorOpts}
                value={employmentSector}
                setValue={setEmploymentSector}
            />

            <div className='col-span-full mt-10'>
                <h3 className='text-xl font-medium leading-6'>
                    Credit Behaviour Information
                </h3>
            </div>

            <FormSelect 
                label='Do you currently have any outstanding debts or loans?'
                name='outstandingLoans'
                id="outstandingLoans"
                content={optionsYN}
                value={outstandingLoans}
                setValue={setOutstandingLoans}
            />

            <FormSelect 
                label='Have you taken a loan from any financial institution?'
                name='loansTaken'
                id="loansTaken"
                content={optionsYN}
                value={loansTaken}
                setValue={setLoansTaken}
            />

            <FormSelect 
                label='What is the length of history of your credit/loan borrowing or payment history?'
                name='custRelTime'
                id="custRelTime"
                content={billsHistoryOpts}
                value={custRelTime}
                setValue={setCustRelTime}
            />

            <FormSelect 
                label='Have you ever defaulted on or missed a loan payment?'
                name='loanDefaultStatus'
                id="loanDefaultStatus"
                content={optionsYN}
                value={loanDefaultStatus}
                setValue={setLoanDefaultStatus}
            />

            <FormSelect 
                label='How many times did you miss payment on your loan?'
                name='defaultedLoanStatus'
                id="defaultedLoanStatus"
                content={missedPaymentOptions}
                value={defaultedLoanStatus}
                setValue={setDefaultedLoanStatus}
            />

            <div className='col-span-full mt-10'>
                <h3 className='text-xl font-medium leading-6'>
                    Bills Payment Information
                </h3>
            </div>

            <FormSelect 
                label='What are the bills that you are responsible for paying?'
                name='billsOption'
                id="billsOption"
                content={billPaymentOptions}
                value={billsOption}
                setValue={setBillsOption}
            />

            <FormSelect 
                label='What are the length of history of your bills payment history?'
                name='billsHistory'
                id="billsHistory"
                content={billsHistoryOpts}
                value={billsHistory}
                setValue={setBillsHistory}
            />

            <FormSelect 
                label='How many times did you miss payment on your bills?'
                name='billsHistoryStatus'
                id="billsHistoryStatus"
                content={missedPaymentOptions}
                value={billsHistoryStatus}
                setValue={setBillsHistoryStatus}
            />

            <FormSelect 
                label='Do you have 3 recent months loan payment history?'
                name='loanPaymentHistory'
                id="loanPaymentHistory"
                content={optionsYN}
                value={loanPaymentHistory}
                setValue={setLoanPaymentHistory}
            />

            <FormSelect 
                label='Do you have 3 recent months bills payment history?'
                name='billPaymentHistory'
                id="billPaymentHistory"
                content={optionsYN}
                value={billPaymentHistory}
                setValue={setBillPaymentHistory}
            />

            <div className='col-span-full mt-10'>
                <h3 className='text-xl font-medium leading-6'>
                    Savings Habit Information
                </h3>
            </div>

            <div className="col-span-full">
                <FormSelect 
                    label='Do you have a savings accouunt held with a financial institution, pension or investment company that you can use for emergencies, such as sickness or job loss? Please note that regular savings and checking accounts may not meet this requirement.'
                    name='savingHabits'
                    id="savingHabits"
                    content={optionsYN}
                    value={savingHabits}
                    setValue={setSavingHabits}
                />
            </div>

            <FormInput 
                label='Month 1'
                name='savingsFreq1'
                id="savingsFreq1"
                type='number'
                value={savingsFreq1}
                setValue={setSavingsFreq1}
            />

            <FormInput 
                label='Month 2'
                name='savingsFreq2'
                id="savingsFreq2"
                type='number'
                value={savingsFreq2}
                setValue={setSavingsFreq2}
            />

            <FormInput 
                label='Month 3'
                name='savingsFreq3'
                id="savingsFreq3"
                type='number'
                value={savingsFreq3}
                setValue={setSavingsFreq3}
            />

            <FormSelect 
                label='If you have appropriate insurance coverage, please indicate which of the following you have.'
                name='typeOfInsurance'
                id="typeOfInsurance"
                content={insuranceOpts}
                value={typeOfInsurance}
                setValue={setTypeOfInsurance}
            />
            
            <div className='col-span-full md:col-span-2'>
                <FormSelect 
                    label='Do you regularly save a minimum of 5% of your monthly income for emergency purposes or future financial goals?'
                    name='eligibleSavingsHabit'
                    id="eligibleSavingsHabit"
                    content={optionsYN}
                    value={eligibleSavingsHabit}
                    setValue={setEligibleSavingsHabit}
                />
            </div>

            <div className='col-span-full mt-10'>
                <h3 className='text-xl font-medium leading-6'>
                    Loan Information
                </h3>
            </div>

            <FormInput 
                label='Loan amount'
                name='amountReq'
                id="amountReq"
                type='number'
                value={amountReq}
                setValue={setAmountReq}
            />

            <FormSelect 
                label='What is the purpose of the loan?'
                name='purpose'
                id="purpose"
                content={purposeOfLoanOpts}
                value={purpose}
                setValue={setPurpose}
            />

            <FormSelect 
                label='What is the desired payment plan?'
                name='paymentPlan'
                id="paymentPlan"
                content={paymentPlanOpts}
                value={paymentPlan}
                setValue={setPaymentPlan}
            />

            <FormSelect 
                label='What is the desired payment duration?'
                name='paymentDuration'
                id="paymentDuration"
                content={paymentDurationOpts}
                value={paymentDuration}
                setValue={setPaymentDuration}
            />

            <FormInput 
                label='Provide your source of income for loan repayment'
                name='repaySource'
                id="repaySource"
                type='text'
                value={repaySource}
                setValue={setRepaySource}
            />

            <FormSelect 
                label='What is the frequency of your income?'
                name='freqIncome'
                id="freqIncome"
                content={incomeFrequencyOpts}
                value={freqIncome}
                setValue={setFreqIncome}
            />

            <FormSelect 
                label='Please select your preferred automatic loan repayment method for your CedisPay loan'
                name='autoDeduction'
                id="autoDeduction"
                content={automaticPaymentOpts}
                value={autoDeduction}
                setValue={setAutoDeduction}
            />

            <div>
                <label htmlFor="startDate" className='form-label'>
                    Select loan start date
                </label>
                <input 
                    type="date"
                    name={"startDate"}
                    id={"startDate"}
                    className="form-input disabled:opacity-75 disabled:bg-gray-200 placeholder:text-gray-700 disabled:cursor-not-allowed"
                    value={startDate}
                    onChange={e => setStartDate(e.target.value)}
                    min={new Date().toISOString().split('T')[0]}
                    max={nextMonthStartDate()}
                />
            </div>

            <FormSelect 
                label='Type of Customer'
                name='loanKind'
                id="loanKind"
                content={payrollLoanOpts}
                value={loanKind}
                setValue={setLoanKind}
            />

            <FormSelect 
                label='Kindly select a relationship manager'
                name='relManager'
                id="relManager"
                content={relationshipManagers}
                value={relManager}
                setValue={setRelManager}
            />

            {loanKind === "Promo Employer" && (
            <>
                <div className='col-span-full mt-10'>
                    <h3 className='text-xl font-medium leading-6'>
                        Eligibility Information
                    </h3>
                </div>

                <FormSelect 
                    label="Are you currently in regular employment?"
                    name='regularEmployment'
                    id="regularEmployment"
                    content={optionsYN}
                    value={regularEmployment}
                    setValue={setRegularEmployment}
                />

                <FormSelect 
                    label="Relationship history with CedisPay or Relationship history with Pension Trustee / Payroll Service provider?"
                    name='relPensionTrustee'
                    id="relPensionTrustee"
                    content={relPensionTrusteeOpts}
                    value={relPensionTrustee}
                    setValue={setRelPensionTrustee}
                />

                <FormSelect 
                    label="Have you been in full compliance with all terms and conditions of your previous loans with CedisPay?"
                    name='pensionCompliance'
                    id="pensionCompliance"
                    content={optionsYN}
                    value={pensionCompliance}
                    setValue={setPensionCompliance}
                />
            </>
            )}

            <div className='col-span-full mt-10'>
                <h3 className='text-xl font-medium leading-6'>
                    Guarantor Information
                </h3>
            </div>

            <FormInput 
                label="Guarantor's first name"
                name='guarantorFirstName'
                id="guarantorFirstName"
                type='text'
                value={guarantorFirstName}
                setValue={setGuarantorFirstName}
            />

            <FormInput 
                label="Guarantor's last name"
                name='guarantorLastName'
                id="guarantorLastName"
                type='text'
                value={guarantorLastName}
                setValue={setGuarantorLastName}
            />

            <FormInput 
                label="Guarantor's phone number"
                name='guarantorPhone'
                id="guarantorPhone"
                type='text'
                value={guarantorPhone}
                setValue={setGuarantorPhone}
            />

            <FormInput 
                label="Guarantor's address"
                name='guarantorAddress'
                id="guarantorAddress"
                type='text'
                value={guarantorAddress}
                setValue={setGuarantorAddress}
            />

            <FormInput 
                label="Your relationship to guarantor"
                name='guarantorRelationship'
                id="guarantorRelationship"
                type='text'
                value={guarantorRelationship}
                setValue={setGuarantorRelationship}
            />

            <FormInput 
                label="Guarantor's GhanaCard number"
                name='guarantorIdNumber'
                id="guarantorIdNumber"
                type='text'
                value={guarantorIdNumber}
                setValue={setGuarantorIdNumber}
            />

            <FormInput 
                label="Guarantor's digital address"
                name='guarantorDigitalAddress'
                id="guarantorDigitalAddress"
                type='text'
                value={guarantorDigitalAddress}
                setValue={setGuarantorDigitalAddress}
            />

            <div>
                <label htmlFor="guarantorDob" className='form-label'>
                    Guarantor's date of birth
                </label>
                <input 
                    type="date"
                    name={"guarantorDob"}
                    id={"guarantorDob"}
                    className="form-input disabled:opacity-75 disabled:bg-gray-200 placeholder:text-gray-700 disabled:cursor-not-allowed"
                    value={guarantorDob}
                    onChange={e => setGuarantorDob(e.target.value)}
                    min={"1930-01-01"}
                    max={new Date().toISOString().split('T')[0]}
                />
            </div>

            <NextOfKin 
                nextOfKin={nextOfKin}
                setNextOfKin={setNextOfKin}
                nextOfKinPhone={nextOfKinPhone}
                setNextOfKinPhone={setNextOfKinPhone}
                nextOfKinAddress={nextOfKinAddress}
                setNextOfKinAddress={setNextOfKinAddress}
                nextOfKinRelationship={nextOfKinRelationship}
                setNextOfKinRelationship={setNextOfKinRelationship}
            />

            <div className='col-span-full mt-10'>
                <h3 className='text-xl font-medium leading-6'>
                    Files to upload
                </h3>
            </div>

            <FormUpload 
                label='Upload proof of income'
                name='incomeProof'
                id="incomeProof"
                value={incomeProof}
                setValue={setIncomeProof}
            />

            <FormUpload 
                label='Upload proof of residence'
                name='residenceProof'
                id="residenceProof"
                value={residenceProof}
                setValue={setResidenceProof}
            />

            <FormUpload 
                label='Upload bank statement or momo statement'
                name='bankOrMomoStatement'
                id="bankOrMomoStatement"
                value={bankOrMomoStatement}
                setValue={setBankOrMomoStatement}
            />

            <FormUpload 
                label='Upload employee signed MOU'
                name='employerMOUFile'
                id="employerMOUFile"
                value={employerMOUFile}
                setValue={setEmployerMOUFile}
            />

            <div className="col-span-full mt-10">
                <h3 className='text-xl font-medium leading-6'>
                    Attach other files 
                </h3>
            </div>

            <OtherDocsUpload 
                setValue={setOtherDocs}
            />

            <LoanAuth />

            <FormInput 
                label='Signed Name'
                name='signedName'
                id="signedName"
                type='text'
                value={signedName}
                disabled={true}
            />

            <div className="col-span-full mt-2">
                <div className="inline-flex items-center gap-2">
                    <Button 
                        btnText={"Check eligibility"}
                        btnType={"submit"}
                        btnClasses={"text-sm bg-light-blue px-3 py-2 rounded-md text-white"}
                        processing={processing}
                    />
                </div>
            </div>

        </form>

        <Modal isOpen={isOpen} closeModal={closeModal}>
            <Dialog.Panel className="w-full max-w-md md:max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 flex items-center mb-4">
                    Pre-Check Status: <span className={`ml-1 px-3 py-1.5 text-white font-medium rounded-full text-xs capitalize ${preCheckData?.status === 'accepted' ? 'bg-emerald-400' : preCheckData?.status === 'rejected' ? 'bg-red-400' : ''}`}>{preCheckData?.status}</span>
                </Dialog.Title>
                <EligCheck 
                    status={preCheckData?.status}
                    data={preCheckData}
                    loanType={"employer"}
                    closeModal={closeModal}
                />
            </Dialog.Panel>
        </Modal>
        </>
    )
}

export default Employer