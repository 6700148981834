import { Topbar, Sidebar } from "../../components";
import { useLocation } from 'react-router-dom';
import Loanme from './Loanme';
import Pension from './Pension';
import Pencash from './Pencash';
import Capitalme from './Capitalme';
import CedisCredit from './CedisCredit';
import Cashme from './Cashme';
import Investment from './Investment';
import Employer from './Employer';

function Questions() {

    const loanType = useLocation().pathname.split("/")[2];
    const client = JSON.parse(localStorage.getItem("client"));

    const switchLoans = () => {
        switch (loanType) {
            case "pension":
                return <Pension client={client} />;
            case "pensioncash":
                return <Pencash client={client} />;
            case "capitalme":
                return <Capitalme client={client} />;
            case "cediscredit":
                return <CedisCredit client={client} />;
            case "cashme":
                return <Cashme client={client} />;
            case "investment":
                return <Investment client={client} />;
            case "employer":
                return <Employer client={client} />;
            default:
                return <Loanme client={client} />;
        }
    }

    return (
        <div className='main-container'>
            <Topbar />
            <Sidebar />

            <main className='main-content'>
                <section className='section-container'>

                <h3 className='text-lg md:text-3xl font-medium leading-6 text-display mb-10'>
                    <span className=''>
                        Kindly fill all fields in this section
                    </span>
                </h3>

                <div className='border border-slate-300 rounded-md px-3 py-6'> 
                    {switchLoans()}
                </div>

                </section>
            </main>
        </div>
    )
}

export default Questions