import React from 'react'

function LoanAuth() {
    return (
        <div className='col-span-full mt-10 py-6 px-4 lg:p-10 bg-slate-50 rounded-md text-slate-700'>
            <h3 className='text-2xl font-medium leading-6 mb-6'>
                Authorization 
            </h3>

            <h4 className='text-lg font-medium leading-6 mb-2'>
                Loan Authorization
            </h4>   

            <p className='mb-6'>
                I, hereby apply for loans stated in Sections of this application the information I have provided hee is to the best of my knowledge true and correct. I authorize CedisPay Micro-Credit Enterprice to verify the correctneess of this information and also to obtain additional information if it deems necessary in evaluating my loan application.
            </p>

            <h4 className='text-lg font-medium leading-6 mb-2'>
                Disclosure to Credit Reference Bureaus
            </h4>   

            <p className='mb-3'>
                CedisPay will obtain information about you from the credit reference bureaus to check your credit status and identity. The bureaus will record our enquiries which may be seen by other institutions that make their own credit enquiries about you. CedisPay shall also disclose your credit transactions to credit reference bureaus in accordance with the <strong>Credit Reporting Regulations, 2020 (L.I 2394)</strong>
            </p>
        </div>
    )
}

export default LoanAuth