import { useState } from 'react';
import { authStaff } from '../../../utils/constants';
import { fetchRequests } from '../../../Requests/getRequests';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken } from '../../../slices/staffSlice';
import { Loader, Tooltip } from "../../../components";
import { show } from '../../../slices/toastSlice';
import { ExclamationCircleIcon, CheckBadgeIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

function ToggleSuspend({ _id, status, setClientList }) {

    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [process, setProcess] = useState(false);

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const toggleRequest = async(e) => {
        e.preventDefault();
        setProcess(true);

        let toggleUrl = `/auth/staff/client/disable`;
        let headers = authStaff(accessToken);

        let data = {
            _id : _id
        }

        let res = await fetchRequests(toggleUrl, "PUT", headers, {}, data)
        setProcess(false);

        if(res?.code === 401){
			navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        }

        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : `${res?.err}`
            }))
            return;
        }

        dispatch(show({
            type : "success",
            state : true,
            message : `${res?.message}`
        }))

        setClientList(res?.clients)

    }
    
    return (
        <span className={classNames ("cursor-pointer inline-flex items-center", status ? "text-emerald-700 hover:text-emerald-600" : "text-red-500 hover:text-red-700")} onClick={toggleRequest}>
            {status ? 
                <Tooltip text={"Restore Client"} position="left">
                    <CheckBadgeIcon 
                        className="w-5 h-5"
                    />
                </Tooltip>
                : 
                <Tooltip text={"Disable Client"} position="left">
                    <ExclamationCircleIcon 
                        className="w-5 h-5" 
                    />
                </Tooltip>
            } {process && <Loader color="text-gray-600 ml-3" size="4" />}
        </span>
    )
}

export default ToggleSuspend