import React from 'react'
import { Link } from 'react-router-dom'

function LoanSearch({ loan }) {
    return (
        <Link to={`/${loan?.state}/${loan?._id}`} className='px-4 py-3.5 border border-gray-200 rounded-md hover:bg-gray-50 flex items-center divide-x divide-slate-200'>
            <div className='pl-0 px-6'>{`${loan?.client?.otherNames} ${loan?.client?.surname}`}</div>
            <div className='px-6'>{loan?.phone}</div>
            <div className='px-6'>{loan?.type}</div>
        </Link>
    )
}

export default LoanSearch