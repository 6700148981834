// import { percentDaily } from '../../utils/constants';
import { DocumentDuplicateIcon } from '@heroicons/react/24/solid';
import { NumCount } from "../../components";
import { dateToHumanFormatShort } from '../../utils/constants';

function LoansSum({ diffLoans, days }) {

    return (
        <div className='border border-gray-200/[.8] rounded-lg p-6 inline-flex flex-col space-y-2 shadow-sm'>
            <div className='inline-flex flex-row items-center justify-between text-sm'>
                <span className='font-medium'>Incoming Loans</span>
                <DocumentDuplicateIcon className='w-5 h-5 sm:hidden md:block text-light-blue' />
            </div>

            <div>
                <span className='text-4xl font-bold tabular-nums'>
                    <NumCount symbol={""} number={diffLoans?.current} deci={""} />
                </span>
            </div>

            <div>
                <span className='text-xs font-light text-gray-500'>
                    {/* + {`${percentDaily(diffLoans?.prev, diffLoans?.current)}`}% from yesterday */}
                    {days?.firstDay !== null && `From ${dateToHumanFormatShort(days?.firstDay)} to ${dateToHumanFormatShort(days?.lastDay)}`}
                </span>
            </div>
        </div>
    )
}

export default LoansSum